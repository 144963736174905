import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Question} from '../../../pages/LessonsEdit/components';
import {Loader} from '../Loader/Loader';

export const DraggableQuestions =({questionPoints,questions, droppableId='questions' ,onUpdateQuestion,onDeleteQuestion,onResortQuestions})=>{
	const handleOnDragEnd = (result) => {
		const destination = result.destination; // {droppable?Id : string, index : number}
		const source = result.source; // {droppable?Id : string, index : number}
		onResortQuestions(source.index,destination.index);
	};

	return(
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId={droppableId}>
				{(provided) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						{questions.map((question, index) => (
							<Draggable key={question._id} draggableId={question._id} index={index}>
								{(provided) => (
									<div
										key={question._id}
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										className='p-3 mb-2 bg-gray-800 rounded'
									>
										<Question
											question={question}
											points={questionPoints.find(qp=>qp.question===question._id).points}
											questionIndex={index+1}
											onDeleteQuestion={onDeleteQuestion}
											onEditQuestion={onUpdateQuestion}

										/>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};