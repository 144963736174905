import PropTypes from 'prop-types';
import {Response} from '../Response';
import {ResponseForm} from '../ResponseForm';
import {useState} from 'react';
import {EditDeleteActions} from '../../../../components/layout';

export const QuestionRender =({responses,question, points, questionIndex,onEdit,refetchResponses, onDelete}) =>{
	const [addResponse,setAddResponse] = useState(false);
	return (
		
		<div className='bg-midnight-light flex flex-col gap-1 px-4 py-2 rounded-md'>
			<div className={'flex justify-between mt-2'}>
				<h4 className={'flex font-bold text-2xl'}>
					{questionIndex}.
					<span className={'pl-3'}
						dangerouslySetInnerHTML={{__html: question.label}}
					/>
				</h4>
				<div className={'flex gap-4'}>
					<button
						className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}
						type={'button'}
						onClick={() => setAddResponse(true)}
					>
						<div
							className={'font-mono w-[20px] h-[20px] flex justify-center items-center cursor-pointer font-medium text-white bg-background-dark dark:bg-brightLilac-default rounded-3xl'}>
							+
						</div>
						<p>Add Answer</p>
					</button>
					<EditDeleteActions onDelete={onDelete} onEdit={onEdit}/>
				</div>

			</div>

			<h2 className={'font-bold'}>Points: {points}</h2>


			<div className='responses-list mt-3 mb-3'>
				{question.responses.length ? (
					responses.map((response) => (
						<Response response={response} refetchResponses={refetchResponses} question={question} key={response._id}/>
					))
				) : (
					<p>There is no answers yet</p>
				)}
			</div>

			<ResponseForm
				question={question}
				isOpen={addResponse}
				setIsOpen={setAddResponse}
			/>
		</div>
			
	);
};

QuestionRender.propTypes = {
	question: PropTypes.shape({
		question: PropTypes.string.isRequired,
		points: PropTypes.string.isRequired,
	}).isRequired,
	questionIndex:PropTypes.number.isRequired,
	points:PropTypes.arrayOf(PropTypes.number),
	onEdit:PropTypes.func.isRequired,
	onDelete:PropTypes.func.isRequired,
};