import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useMutation, useQuery} from '@apollo/client';
import {LessonQueries} from '../../actions/Lessons.queries';
import {LessonsMutations} from '../../actions/Lessons.mutations';
import Modal from '../../../../components/ui/Modal/Modal';
import {CheckInQuestionEditFormRender} from './CheckInQuestionEdit.render';
import {Loader} from '../../../../components';

export const CheckInQuestionEditFormLoad = ({checkInQuestionId, lessonId, isOpen, setIsOpen }) => {
	const [formState, setFormState] = useState({
		curriculum: '',
		question: '',
		start: false,
	});

	const { data, loading, error } = useQuery(LessonQueries('CheckInQuestionFindOne'),
		{
			variables: { filter: { _id: checkInQuestionId , class: lessonId} },
			skip: !isOpen,
		});

	const [isChanged, setIsChanged] = useState(false);
	const [isDeletionModal, setIsDeletionModal] = useState(false);

	const [ checkInQuestionUpdateOne, { loading: isUpdating, error: isUpdateError } ] =
		useMutation(LessonsMutations('CheckInQuestionUpdateOne'), {
			refetchQueries: [{ query: LessonQueries('CheckInQuestionsFindMany') }],
			awaitRefetchQueries: true
		});
	
	const [ChekInQuestionDeleteOne, { loading: isDeleteUpdating, error: isDeleteError }] = useMutation(LessonsMutations('CheckInQuestionDeleteOne'), 
		{
			refetchQueries: [{ query: LessonQueries('CheckInQuestionsFindMany'),
				awaitRefetchQueries: true
			}]
		});

	useEffect(() => {
		if(isOpen && data && data.CheckInQuestionFindOne){
			const { curriculum, question, start } = data.CheckInQuestionFindOne;
			setFormState({
				curriculum,
				question,
				start,
			});
			setIsChanged(false);
		}
	},[data, isOpen]);

	const onSubmit = async (e) => {
		e.preventDefault();
		if (!isChanged) return;
		try {
			await checkInQuestionUpdateOne({
				variables: {
					recordId: checkInQuestionId,
					record: {
						curriculum: formState.curriculum,
						question: formState.question,
						start: formState.start
					},
					filter:{
						_id: checkInQuestionId
					}
				}
			});
			setIsOpen(false);
		} catch (error) {
			console.log('Error updating CheckInQuestion: ', error.message);
		}
	};

	const handleDelete = async () => {
		try {
			await ChekInQuestionDeleteOne({
				variables: {
					_id: checkInQuestionId
				}
			});
			setIsOpen(false);
		} catch (error) {
			console.log('Error deleting CheckInQuestion: ', error.message);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleRadioChange = (e) => {
		const { value } = e.target;
		setFormState((prev) => ({ ...prev, start: value === 'true' }));
		setIsChanged(true);
	};

	const allFieldsFilled =
		Object.values(formState).every((field) => field !== '');

	if(loading) return <Loader/>;
	if(error) return <span className={'font-mona f-600 white p-3'}>Error...</span>;
	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<CheckInQuestionEditFormRender
				formState={formState}
				onSubmit={onSubmit}
				handleChange={handleChange}
				handleRadioChange={handleRadioChange}
				close={() => setIsOpen(false)}
				isLoading={isUpdating || isDeleteUpdating}
				isError={isUpdateError || isDeleteError}
				isChanged={isChanged}
				allFieldsFilled={allFieldsFilled}
				isDeletionModal={isDeletionModal}
				setIsDeletionModal={setIsDeletionModal}
				handleDelete={handleDelete}
			/>
		</Modal>
	);
};

CheckInQuestionEditFormLoad.propTypes = {
	checkInQuestionId: PropTypes.string.isRequired,
	lessonId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};