import {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Chart, LineController,PointElement, CategoryScale, LinearScale, LineElement } from 'chart.js';
import {format, parseISO} from 'date-fns';

Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement);
const initChart = (chartCtx,config)=>{
	return new Chart(chartCtx,config);
};

export const HistoryLineChart = ({historyData})=>{
	const sortedData = [...historyData].sort( (a,b)=> new Date(a.date) - new Date(b.date) );
	const canvasRef = useRef(null);
	const chartRef = useRef(null);

	const labels =[
		'',
		...sortedData.map(d => format(parseISO(d.date) , 'dd-MM-yy')),
		// ...sortedData.map(d => d),
		''
	];
	const dataSet =[ 0,...sortedData.map(h=>h.count), 0];


	const data = {
		labels: labels,
		datasets: [{
			label: 'My First Dataset',
			data: dataSet,
			fill: false,
			borderColor: 'rgba(203, 60, 255, 1)',
			tension: 0.1,
			responsive:true
		}]
	};
	useEffect(()=>{
		if (chartRef.current) {
			chartRef.current.destroy();
		}
		if (canvasRef.current){
			chartRef.current = initChart(canvasRef.current,{
				type: 'line',
				data: data,
			});
		}
		return () => chartRef.current && chartRef.current.destroy();

	},[canvasRef,historyData]);


	return (
		<div className={'max-w-full max-h-[30vh] aspect-video bg-cloudyBlue-light border-brightLilac-default border rounded p-4'}>
			<canvas
				className={''}
				ref={canvasRef}
			/>
		</div>
	);
};

HistoryLineChart.prototype = {
	historyData: PropTypes.arrayOf(PropTypes.shape({
		count:PropTypes.number.isRequired,
		date:PropTypes.string.isRequired,
	})).isRequired
};