import gql from 'graphql-tag';

const queries = {
	districtFindMany: gql`
		query DistrictFindMany($filter: FilterFindManydistrictInput, $skip: Int, $sort: SortFindManydistrictInput){
			  DistrictFindMany(filter: $filter, skip: $skip, sort: $sort) {
			  	_id
				name
				state
				country
				organization
				createdAt
				updatedAt
			  }
		}
	`,
	districtFindOne: gql`
		query DistrictFindOne($filter: FilterFindOnedistrictInput, $skip: Int, $sort: SortFindOnedistrictInput){
			DistrictFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
                name
                state
                country
                organization
                createdAt
                updatedAt
			}
		}
	`,
	UsersByOrganizationFindMany: gql`
        query UsersByOrganizationFindMany($organization: String!, $type: String!){
            UsersByOrganizationFindMany(organization: $organization, type: $type) {
                users {
                    _id
                    id
                    firstName
                    lastName
                    userName
                    tag
                    bio
                    birthday
                    email
                    profilePicture{
                        type
                        url
                        title
                    }
                    points
                    type
                    lastLogin
                    organization
                    cleverId
                    cleverToken
                    classLinkId
                    cleverSync
                    classLinkSync
                    deedlyAuthenticationToken
                    deedlyUserId
                    createdAt
                    updatedAt
                }
                total
            }
		}
	`,
	OrganizationUserHistoryFindMany: gql`
        query OrganizationUserHistoryFindMany(
            $organization: String!
            $type: String       # userType
            $dateField: String! # createdAt | lastLogin
            $startDate: String
            $endDate: String
        ){
            OrganizationUserHistoryFindMany(
                organization: $organization
                type: $type 
                dateField: $dateField
                startDate: $startDate
                endDate: $endDate
            ) {
                date
                count
            }
        }
    `,
};

export const DistrictQueries = ( value ) => {
	return queries[ value ];
};