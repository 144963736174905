import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

export const SchoolFindOneById = ({ user }) => {
	const SCHOOL_USER_FIND_ONE = query('schoolUserFindOne');

	const { data, loading, error } = useQuery(SCHOOL_USER_FIND_ONE, {
		variables: { filter: { user } },
	});

	if (loading) return null;
	if (error) return null;

	return data?.schoolUserFindOne?.school?._id;
};

SchoolFindOneById.propTypes = {
	user: PropTypes.string.isRequired
};