import React from 'react';
import Button from '../../../../components/ui/Button';
import {CoursesTableMainWrapper} from '../../../Courses/Courses.style';
import PropTypes from 'prop-types';
import {StyledTableRow} from '../../../Users/Users.style';
import {formatDateString, formatDecimal} from '../../../../tools';
import {PencilIcon} from '../../../../icons';
import {VideoTapForm} from '../../components/VideoTapForm/VideoTapForm.load';
import {VideoTapEditForm} from '../../components/VideoTapForm/VideoTapFormEdit.load';

export const VideoTapRender = ({
	lesson_id,
	VideoTapsData,
	showVideoTapForm,
	setShowVideoTapForm,
	handleEditClick,
	selectedVideoTapId,
	setSelectedVideoTapId,
	showVideoTapEditForm,
	setShowVideoEditTapForm
}) => {
	return <div className={'w-full px-4 text-white'}>
		<div className={'flex justify-between gap-4 items-center mb-4'}>
			<h2 className={'h2'}>VideoTaps</h2>
			<Button type={'button'} className={'bg-brightLilac-default'}
				onClick={() => {
					setSelectedVideoTapId(null);
					setShowVideoTapForm(true);
				}}>
				New VideoTap +
			</Button>
		</div>

		{!VideoTapsData?.VideoTapsFindMany?.length ? (
			<div className={'pt-10 w-100'}>
				<p className={'h4 center'}>This lesson does not have Video Taps yet.</p>
			</div>
		) : (
			<div className={'w-full br border-text-dark border overflow-hidden px-2 pb-4'}>
				<CoursesTableMainWrapper className={'w-100 mt rounded-lg p2.5 '} style={{ boxDecorationBreak: 'unset' }}>
					<thead>
						<tr>
							<th className={'font-mono main-color-background p-2'}>
								<span>Start</span>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<span>End</span>
							</th>
							{/*
							<th className={'font-mono main-color-background p-2'}>
								<span>Spark Library</span>
							</th>
							*/}
							<th className={'font-mono main-color-background p-2'}>
								<span>Tag</span>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<span>Created At & Updated At</span>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<span>Actions</span>
							</th>
						</tr>
					</thead>
					<tbody className={'p-2'}>
						{VideoTapsData.VideoTapsFindMany.map(({_id, start, end, sparkLibrary, tag, createdAt}) => (
							<StyledTableRow key={_id}>
								<td className={'p-2'}>{formatDecimal(start)}</td>
								<td className={'p-2'}>{formatDecimal(end)}</td>
								{/*
								<td className={'p-2'}>
									{sparkLibrary && (
										<SparkLibraryFindOne _id={sparkLibrary}>
											{(classData) =>
												console.log({classData})
											}
										</SparkLibraryFindOne>
									)}
								</td>
								*/}
								<td className={'p-2'}>{tag}</td>
								<td className={'p-2'}>
									{`${formatDateString(createdAt)} - ${formatDateString(createdAt)}`}
								</td>
								<td className={'cursor-pointer p-2'}>
									<button type='button' onClick={() => handleEditClick(_id)}>
										<PencilIcon className={'cursor-pointer'}/>
									</button>
								</td>
							</StyledTableRow>
						))}
					</tbody>
				</CoursesTableMainWrapper>
			</div>
		)}

		<VideoTapForm
			_id={lesson_id}
			isOpen={showVideoTapForm}
			setIsOpen={setShowVideoTapForm}
		/>
		<VideoTapEditForm
			videoTapId={selectedVideoTapId}
			lesson={lesson_id}
			isOpen={showVideoTapEditForm}
			setIsOpen={setShowVideoEditTapForm}
		/>
	</div>;
};

VideoTapRender.propTypes = {
	lesson_id: PropTypes.string.isRequired,
	VideoTapsData: PropTypes.shape({
		VideoTapsFindMany: PropTypes.arrayOf(
			PropTypes.shape({
				_id: PropTypes.string.isRequired,
				lesson: PropTypes.string.isRequired,
				start: PropTypes.number,
				end: PropTypes.number,
				sparkLibrary: PropTypes.string,
				tag: PropTypes.string,
				deletedAt: PropTypes.string,
				createdAt: PropTypes.string.isRequired,
				updatedAt: PropTypes.string.isRequired,
			})
		),
	}),
	showVideoTapForm: PropTypes.bool.isRequired,
	setShowVideoTapForm: PropTypes.func.isRequired,
	showVideoTapEditForm: PropTypes.bool.isRequired,
	setShowVideoEditTapForm: PropTypes.func.isRequired,
	handleEditClick: PropTypes.func.isRequired,
	selectedVideoTapId: PropTypes.string,
	setSelectedVideoTapId: PropTypes.func.isRequired
};