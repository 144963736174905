import {DistrictRender} from './DistrictRender';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {useHistory} from 'react-router-dom';
import {Loader} from '../../components';

export const DistrictLoad = () => {

	const { loading, error, data, refetch } = useQuery(query('districtFindMany'),{
		variables: {limit:500}
	});

	const history = useHistory();

	if (loading) return <Loader />;
	if (error) return <p>Error: {error.message}</p>;
	if(!data || !data.DistrictFindMany) return <p className={'font-mona f-900 white p-3'}>No Data</p>;

	return <DistrictRender Districts={data ? data.DistrictFindMany : []} refetch={refetch} history={history}/>;
};