import {useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {LessonQueries} from '../../actions/Lessons.queries';
import {LessonsMutations} from '../../actions/Lessons.mutations';
import Modal from '../../../../components/ui/Modal/Modal';
import {VideoTapEditFormRender} from './VideoTapFormEdit.render';
import PropTypes from 'prop-types';

export const VideoTapEditForm = ({ videoTapId, lesson, isOpen, setIsOpen }) => {
	const [formState, setFormState] = useState({
		start: 0,
		end: 0,
		sparkLibrary: '',
		tag: '',
	});

	const { data, loading, error } = useQuery(LessonQueries('VideoTapsFindOne'), {
		variables: { filter: { _id: videoTapId , lesson } },
		skip: !isOpen,
	});

	const [isChanged, setIsChanged] = useState(false);
	const [isDeletionModal, setIsDeletionModal] = useState(false);

	const [VideoTapsUpdateOne, { loading: isUpdating, error: updateError }] =
		useMutation(LessonsMutations('VideoTapsUpdateOne'), {
			refetchQueries: [{ query: LessonQueries('VideoTapsFindMany') }],
			awaitRefetchQueries: true,
		});

	const [VideoTapsDeleteOne, { loading: isDeleteUpdating, error: isDeleteError }] = useMutation(LessonsMutations('VideoTapsDeleteOne'), {
		refetchQueries: [{ query: LessonQueries('VideoTapsFindMany') }],
		awaitRefetchQueries: true,
	});

	const handleDelete = async () => {
		try {
			await VideoTapsDeleteOne({
				variables: { videotap: videoTapId },
			});
			setIsOpen(false);
		} catch (error) {
			console.log('Error deleting VideoTap: ', error.message);
		}
	};

	useEffect(() => {
		if (isOpen && data && data.VideoTapsFindOne) {
			const { start, end, sparkLibrary, tag } = data.VideoTapsFindOne;
			setFormState({
				start,
				end,
				sparkLibrary,
				tag
			});
			setIsChanged(false);
		}
	}, [data, isOpen]);

	const startError = formState.end && parseFloat(formState.end) <= parseFloat(formState.start);
	const endError = formState.start && parseFloat(formState.end) <= parseFloat(formState.start);

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			await VideoTapsUpdateOne({
				variables: {
					_id: videoTapId,
					record: {
						start: parseFloat(formState.start),
						end: parseFloat(formState.end),
						sparkLibrary: formState.sparkLibrary,
						tag: formState.tag,
					},
				},
			});
			setIsOpen(false);
		} catch (error) {
			console.log('Error updating VideoTaps: ', error.message);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const allFieldsFilled =
		Object.values(formState).every((field) => field !== '');

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<VideoTapEditFormRender
				formState={formState}
				onSubmit={onSubmit}
				handleChange={handleChange}
				isLoading={loading || isUpdating || isDeleteUpdating}
				isError={error || updateError || isDeleteError}
				close={() => setIsOpen(false)}
				allFieldsFilled={allFieldsFilled}
				startError={startError}
				endError={endError}
				isChanged={isChanged}
				handleDelete={handleDelete}
				isDeletionModal={isDeletionModal}
				setIsDeletionModal={setIsDeletionModal}
			/>
		</Modal>
	);
};

VideoTapEditForm.propTypes = {
	videoTapId: PropTypes.string.isRequired,
	lesson: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};